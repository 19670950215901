// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const initialFilteredAlliances = () => {
  const item = window.localStorage.getItem('hidden_alliances')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : []
}

export const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    hiddenAlliances: initialFilteredAlliances()
  },
  reducers: {
    setHiddenAlliances: (state, action) => {
      // console.log("setHiddenAlliances payload: ", action.payload)
      // state.hiddenAlliances = action.payload
      // localStorage.setItem('hidden_alliances', JSON.stringify(action.payload))


      const currentAlliances = state.hiddenAlliances
      // console.log("currentRegions: ", currentRegions)
      const alliance = action.payload

      // * state may be empty, so just add new alliance
      if (currentAlliances) {
        for (let i = 0; i < currentAlliances.length; i++) {
          if (currentAlliances[i] === alliance) {
            // * remove element 
            // console.log("Removing region: ", action.payload)
            currentAlliances.splice(i, 1)
            state.hiddenAlliances = currentAlliances
            localStorage.setItem("hidden_alliances", JSON.stringify(currentAlliances))
            return
          }
        }
      } else {
        // * just save the alliance to state & localStorage
        state.hiddenAlliances = action.payload
        localStorage.setItem("hidden_alliances", JSON.stringify(action.payload))
        return
      }

      // * append
      currentAlliances.push(alliance)
      state.hiddenAlliances = currentAlliances
      localStorage.setItem("hidden_alliances", JSON.stringify(currentAlliances))


    },
    removeHiddenAlliances: state => {
      state.hiddenAlliances = []
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('hidden_alliances')
    }
  }
})

export const { setHiddenAlliances, removeHiddenAlliances } = filterSlice.actions

export default filterSlice.reducer
