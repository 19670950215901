// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
// import auth from './authentication'
import filters from './filters'

const rootReducer = {
  // auth,
  navbar,
  layout,
  filters
}

export default rootReducer
